import { postRequest, getRequest, deleteRequest } from '../httpclient/httpClient';

const LOGIN_PATH = 'auth/login';
const LOGOUT_PATH = 'auth/logout';
const LOGIN_WITH_CODE_PATH = 'auth/login_with_code';
const CSRF_PATH = 'auth/csrf';
const USER_INFO = 'auth/user_info';

export async function login(loginRequest) {
  return await postRequest(LOGIN_PATH, loginRequest);
}

export async function logout() {
  return await deleteRequest(LOGOUT_PATH);
}

export async function loginWithCode(codeRequest) {
  return await postRequest(LOGIN_WITH_CODE_PATH, codeRequest);
}

export async function csrf() {
  await getRequest(CSRF_PATH);
}

export async function getLoggedInUserInfo() {
  return await getRequest(USER_INFO);
}

export function hasAccessRight(userInfo, accessRightId) {
  return userInfo && userInfo.accessRightIds && userInfo.accessRightIds.includes(accessRightId);
}

export function hasModuleViewRight(userInfo, moduleId) {
  return userInfo && userInfo.moduleRights &&
    userInfo.moduleRights.some(moduleRight => moduleRight.id === moduleId && moduleRight.view);
}

export function hasModuleEditRight(userInfo, moduleId) {
  return userInfo && userInfo.moduleRights &&
    userInfo.moduleRights.some(moduleRight => moduleRight.id === moduleId && moduleRight.edit);
}

export function isSales(userInfo) {
  return userInfo && userInfo.usageTypeId === usageTypes.Sales;
}

export function isService(userInfo) {
  return userInfo && userInfo.usageTypeId === usageTypes.Service;
}

export function isAudit(userInfo) {
  return userInfo && userInfo.usageTypeId === usageTypes.Audit;
}

export function isFieldForceManagement(userInfo) {
  return userInfo && userInfo.usageTypeId === usageTypes.FieldForceManagement;
}

const usageTypes = {
  Sales: 1,
  Service: 2,
  Audit: 3,
  FieldForceManagement: 4
}

export function is123BT(userInfo) {
  return userInfo && userInfo.subscriptionId === specialSubscriptions.one23BT;
}

const specialSubscriptions = {
  one23BT: 480
}

export const accessRights = {
  PurchaseOrder: 1,
  TakePhoto: 2,
  TakeNote: 3,
  CustomActivity: 4,
  GeoTagging: 5,
  Subscription: 6,
  APIUse: 7,
  DataExport: 8,
  DataImport: 9,
  ClientTagging: 10,
  ProductTagging: 11,
  ProductPresentation: 12,
  Messaging: 13,
  Reminders: 14,
  Social: 15,
  Kpi: 16,
  Product: 17,
  Feed: 18,
  Opportunity: 19,
  WorkOrder: 20,
  VisitType: 21,
  UserTagging: 22,
  AutoSchedule: 23,
  ProductDocumentation: 24,
  Task: 25,
  Gamification: 26,
  Invoice: 27,
  Documents: 28,
  UserRouteAsAddress: 29,
  Jobs: 35,
  UtilityDashboard: 36,
  Capacities: 37,
  BackflowRescheduleReport: 38,
  BillingFile: 39,
  ServiceCall: 40,
  Costs: 41,
  Wmdb: 42,
  ExternalSchedules: 43,
  DynamicDataConfigurationValidationRule: 46,
  ExternalMessage: 47,
  OnRoute: 48,
  Management: 49,
  SendFormToCustomer: 50,
  JobStatus: 51,
  PriceEditable: 52,
  InvoiceInfo: 53,
  MachineLearning: 54,
  ClientInfoUpdate: 55,
  Quickbooks: 56,
  CustomerPortal: 57,
  PolygonMapping: 58,
  RecurringPlan: 59,
  EmailAndTextMarketing: 60,
  CustomFormPdf: 61,
  ClientCustomField: 62,
  CopyFormFromLastVisit: 63,
  AutomatedClientEmailTextMessage: 67,
  ShowBalanceWhenScheduling: 68,
  CustomFormDocument: 69,
  ReminderEmailsForNotes: 70,
  ImageMarking: 71,
  Queue: 73,
  ManageUtilityUsers: 74,
  VpieImportExport: 75,
  CustomDashboards: 81,
  CustomFormOutsideJob: 82,
  ProductV2: 1000,
  DealerNetwork: 1001,
  DistributorNetwork: 1002
}

export const modules = {
  Dashboard: 1,
  Feed: 2,
  Message: 3,
  FieldForceTracking: 4,
  Plans: 5,
  WorkOrders: 6,
  Tasks: 7,
  Visits: 8,
  Activities: 9,
  Clients: 10,
  Products: 11,
  ProductReport: 12,
  Users: 13,
  UserReport: 14,
  CustomForms: 15,
  Settings: 16,
  Subscription: 17,
  Roles: 18,
  Invoices: 19,
  Leaderboard: 20,
  Documents: 21,
  Badges: 22,
  UserRoute: 23,
  Import: 24,
  Jobs: 26,
  UtilityDashboard: 27,
  Capacities: 28,
  Reschedule: 29,
  BillingFile: 30,
  ServiceCall: 31,
  Costs: 32,
  Wmdb: 33,
  ExternalSchedules: 34,
  ExternalMessage: 47,
  JobStatus: 49,
  PredictFields: 50,
  CannotOverbook: 51,
  AppointmentReport: 52,
  ClientInfoUpdates: 53,
  AddFormPreventEdit: 58,
  AutomatedClientEmailsAndTextMessages: 59,
  CanFinishJobWithoutInvoice: 60,
  Queue: 63,
  ManagesUtilitysUsers: 64,
  VpieInvoiceExport: 65,
  VpieLaborCostExport: 66,
  VpieActivityImport: 67,
  ServiceCallReport: 10001,
  SearchAndCreateJob: 10002,
  CustomDashboards: 65,
  CustomFormOutsideJob: 67,
  InventoryAssingedItems: 68,
  Inventory: 69,
  ProductsV2: 1000,
  DealerNetwork: 1001,
  DistributorNetwork: 1002,
  Tagging: 2001,
  ModelTraining: 2002,
  PredictionAndAnalysis: 2003
}

import React, { useState, useContext, useEffect, useRef } from 'react'
import * as resourceService from '../resource/resourceService'
import { MOBILE_WIDTH } from '../theme';

export const ResourceContext = React.createContext();

// eslint-disable-next-line react/prop-types
export function ResourceProvider({ children }) {
  const urlParams = new URLSearchParams(window.location.search);
  const isWebViews = urlParams.get('isWebViews') === 'true' ? true : urlParams.get('isWebViews') === 'false' ? false : null;
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= MOBILE_WIDTH);
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= MOBILE_WIDTH);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const contextStates = {
    componentTitle: useState(''),
    selectedMenuItem: useState(''),
    loggedInUserInfo: useState(null),
    users: useState(null),
    managedUsers: useState(null),
    roles: useState(null),
    teams: useState(null),
    userTags: useState(null),
    subscriberSettings: useState(null),
    clientGroups: useState(null),
    clientStates: useState(null),
    clients: useState(null),
    clientTags: useState(null),
    visitTypes: useState(null),
    clientCities: useState(null),
    regions: useState(null),
    distributorGroups: useState(null),
    distributorRoles: useState(null),
    cities: useState(null),
    distributorTypes: useState(null),
    dealerTypes: useState(null),
    states: useState(null),
    countries: useState(null),
    visitStates: useState(null),
    dateIntervals: useState(null),
    invoiceInformations: useState(null),
    chartTypes: useState(null),
    clientDynamicDataConfigurationFields: useState(null),
    dynamicDataConfigurations: useState(null),
    collapse: useState(localStorage.getItem('menuClosed') === 'true'),
    locations: useState(null),
    deliveryTypes: useState(null),
    corporateIdentities: useState(null),
    collectionMethods: useState(null),
    onlineSalesInclusions: useState(null),
    conceptStatuses: useState(null),
    propertyStatuses: useState(null),
    signboardCounts: useState(null),
    signboardTypes: useState(null),
    dealers: useState(null),
    branches: useState(null),
    distributors: useState(null),
    productGroups: useState(null),
    productTags: useState(null),
    brands: useState(null)
  };

  useEffect(() => {
    const userInfo = contextStates.loggedInUserInfo[0];
    if (userInfo && userInfo.menuClosed !== undefined) {
      contextStates.collapse[1](userInfo.menuClosed);
      localStorage.setItem('menuClosed', userInfo.menuClosed);
    }
  }, [contextStates.loggedInUserInfo[0]]);

  const currentlyFetchingResources = useRef([]);

  function retrieveResources(types) {
    useEffect(() => {
      async function fetch() {
        const typesToRetrieve = types.filter(type => contextStates[type][0] == null && !currentlyFetchingResources.current.includes(type));

        if (typesToRetrieve.length <= 0) {
          return;
        }

        try {
          currentlyFetchingResources.current = currentlyFetchingResources.current.concat(types);
          const resources = await resourceService.multipleResources(typesToRetrieve);

          for (const type in resources) {
            contextStates[type][1](resources[type]);
          }
        } catch (error) {
          console.error(error);
        }

        currentlyFetchingResources.current = currentlyFetchingResources.current.filter(type => !typesToRetrieve.includes(type));
      }
      fetch();
    }, []);
  }

  const refreshResource = async (type) => {
    try {
      currentlyFetchingResources.current.push(type);
      const resources = await resourceService.multipleResources([type]);
      contextStates[type][1](resources[type]);
    } catch (error) {
      console.error(error);
    } finally {
      currentlyFetchingResources.current = currentlyFetchingResources.current.filter((t) => t !== type);
    }
  };

  function setComponentTitle(value) {
    contextStates.componentTitle[1](value);
  }

  function setSelectedMenuItem(value) {
    contextStates.selectedMenuItem[1](value);
  }

  function setLoggedInUserInfo(value) {
    contextStates.loggedInUserInfo[1](value);
  }

  const contextValue = {
    isWebViews,
    isMobileView,
    componentTitle: contextStates.componentTitle[0],
    selectedMenuItem: contextStates.selectedMenuItem[0],
    loggedInUserInfo: contextStates.loggedInUserInfo[0],
    users: contextStates.users[0],
    managedUsers: contextStates.managedUsers[0],
    roles: contextStates.roles[0],
    teams: contextStates.teams[0],
    userTags: contextStates.userTags[0],
    subscriberSettings: contextStates.subscriberSettings[0],
    clientGroups: contextStates.clientGroups[0],
    clientStates: contextStates.clientStates[0],
    clients: contextStates.clients[0],
    clientTags: contextStates.clientTags[0],
    visitTypes: contextStates.visitTypes[0],
    clientCities: contextStates.clientCities[0],
    regions: contextStates.regions[0],
    distributorGroups: contextStates.distributorGroups[0],
    distributorRoles: contextStates.distributorRoles[0],
    cities: contextStates.cities[0],
    distributorTypes: contextStates.distributorTypes[0],
    dealerTypes: contextStates.dealerTypes[0],
    states: contextStates.states[0],
    countries: contextStates.countries[0],
    visitStates: contextStates.visitStates[0],
    dateIntervals: contextStates.dateIntervals[0],
    invoiceInformations: contextStates.invoiceInformations[0],
    chartTypes: contextStates.chartTypes[0],
    clientDynamicDataConfigurationFields: contextStates.clientDynamicDataConfigurationFields[0],
    dynamicDataConfigurations: contextStates.dynamicDataConfigurations[0],
    collapse: contextStates.collapse[0],
    locations: contextStates.locations[0],
    deliveryTypes: contextStates.deliveryTypes[0],
    corporateIdentities: contextStates.corporateIdentities[0],
    collectionMethods: contextStates.collectionMethods[0],
    onlineSalesInclusions: contextStates.onlineSalesInclusions[0],
    dealers: contextStates.dealers[0],
    branches: contextStates.branches[0],
    distributors: contextStates.distributors[0],
    conceptStatuses: contextStates.conceptStatuses[0],
    propertyStatuses: contextStates.propertyStatuses[0],
    signboardCounts: contextStates.signboardCounts[0],
    signboardTypes: contextStates.signboardTypes[0],
    productGroups: contextStates.productGroups[0],
    productTags: contextStates.productTags[0],
    brands: contextStates.brands[0],
    retrieveResources,
    refreshResource,
    setComponentTitle,
    setLoggedInUserInfo,
    setSelectedMenuItem,
    setCollapse: contextStates.collapse[1]
  };

  // retrieve resources needed initially, uncomment below for first one
  /*
  useEffect(() => {
    async function fetch() {
      try {
        const resources = await resourceService.multipleResources(['subscriberSettings']);
        contextStates.subscriberSettings[1](resources.subscriberSettings);
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, []);
  */
  return (
    <ResourceContext.Provider value={contextValue}>
      {children}
    </ResourceContext.Provider>
  );
}

export default ResourceProvider;
export const useResourcesContext = () => useContext(ResourceContext);
